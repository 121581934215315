<template>
  <div style="width: 100%;height:100vh">
    <video style="width: 100%;height: 100%;object-fit: cover" autoplay="autoplay" loop="loop" muted="true"
           type="video/mp4" class="e_video e_video-000 p_video"
           webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" x5-video-player-type="h5"
           x5-video-orientation="h5" x5-video-player-fullscreen="true" controls="">
      <source src="https://oss.pigwan.net/wapi/upload/video/2022/01/hnxjlnvideo_home.mp4">
    </video>
    <div class="content">
      <div class="content-box">
        <p class="title"> 卓越奋斗 &nbsp; 换取未来 </p>
        <p class="title_en">OUTSTANDING STRUGGLE,EXCHANGE FOR THE FUTURE</p>
      </div>
      <div class="btns">
        <div class="btn" style="margin-right: 20px;">绿色能源</div>
        <div class="btn" style="margin-left: 20px;">节能环保</div>
      </div>
      <div class="nav">
        <div class="nav_item">
          激情
        </div>
        <div class="nav_item">
          创新
        </div>
        <div class="nav_item">
          诚信
        </div>
        <div class="nav_item">
          进步
        </div>
      </div>
      <div class="line">
        <img src="https://oss.pigwan.net/wapi/upload/images/2022/01/line.png" alt="">
      </div>
    </div>
    <div class="copyright">
      Copyright © 许继绿能有限公司 &nbsp;&nbsp;备案号 <a href="http://www.beian.miit.gov.cn">{{ website.icp }}</a><span>&nbsp;&nbsp; 技术支持：<a href="https://www.pigwan.net">Pigwan</a></span>
    </div>
  </div>

</template>

<script>

import state from "../../store/state";

export default {
  name: "Home",
  data() {
    return {
      website:{}
    };
  },
  mounted(){
    document.title = "首页 - "+state.websiteSet.website.title
    this.website = state.websiteSet.website
  },
  components: {},
  methods: {}
};
</script>

<style scoped>
video::-webkit-media-controls {
  display: none !important;
}

.content {
  position: absolute;
  left: 0;
  top: calc(100vh - 70vh);
  text-align: center;
  width: 100%;
  color: #FFFFFF;
}

.content-box {
  display: inline-block;
  line-height: 1rem;
}

.title {
  font-size: 3rem;
}

.title_en {
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 4px;
}

.btns {
  margin-top: 80px;
}

.btn {
  font-size: 1.2rem;
  display: inline-block;
  width: 200px;
  height: 40px;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  line-height: 40px;
}
.nav{
  margin-top: 80px;
}
.nav_item{
  display: inline-block;
  margin: 0 10px;
  font-size:1.2rem;
  font-weight: 300;
  width: 120px;
  text-align: left;
}
.line{
  margin-top: 30px;
  width: 540px;
  display: inline-block;
}
.line img{
  width: 100%;
}
.copyright{
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  font-weight: 300;
}
.copyright a:link{
  color: #FFFFFF;
}
</style>
